export enum Section {
  AGENCY_PHONE_NUMBER_DISPLAYED = 'AGENCY_PHONE_NUMBER_DISPLAYED',
  SECTION_ADPAGE_IMAGE_CAROUSEL_CONTACT = 'SECTION_ADPAGE_IMAGE_CAROUSEL_CONTACT',
  CONTACT_FORM_MOBILE_MODAL_DISPLAYED = 'CONTACT_FORM_MOBILE_MODAL_DISPLAYED',
  SCHEDULE_VISIT_FORM_MOBILE_MODAL_DISPLAYED = 'SCHEDULE_VISIT_FORM_MOBILE_MODAL_DISPLAYED',
  SECTION_ADPAGE_BOTTOM_WHATSAPP = 'SECTION_ADPAGE_BOTTOM_WHATSAPP',
  SECTION_ADPAGE_BOTTOM_LINE = 'SECTION_ADPAGE_BOTTOM_LINE',
  SECTION_ADPAGE_BOTTOM_MESSENGER = 'SECTION_ADPAGE_BOTTOM_MESSENGER',
  SECTION_ADPAGE_BOTTOM_VIBER = 'SECTION_ADPAGE_BOTTOM_VIBER',
  SECTION_ADPAGE_BOTTOM_SKYPE = 'SECTION_ADPAGE_BOTTOM_SKYPE',
  SECTION_ADPAGE_RIGHT_WHATSAPP = 'SECTION_ADPAGE_RIGHT_WHATSAPP',
  SECTION_ADPAGE_RIGHT_LINE = 'SECTION_ADPAGE_RIGHT_LINE',
  SECTION_ADPAGE_RIGHT_MESSENGER = 'SECTION_ADPAGE_RIGHT_MESSENGER',
  SECTION_ADPAGE_RIGHT_VIBER = 'SECTION_ADPAGE_RIGHT_VIBER',
  SECTION_ADPAGE_RIGHT_SKYPE = 'SECTION_ADPAGE_RIGHT_SKYPE',
  SECTION_ADFORM_APPLY = 'SECTION_ADFORM_APPLY',
  SECTION_ADPAGE_PROJECTS_MODEL_CONTACT = 'SECTION_ADPAGE_PROJECTS_MODEL_CONTACT',
  SECTION_ADPAGE_BOTTOM_CONTACT_UNBOOSTED = 'SECTION_ADPAGE_BOTTOM_CONTACT_UNBOOSTED',
  SECTION_ADPAGE_CALL = 'SECTION_ADPAGE_CALL',
  SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE = 'SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE',
  SECTION_ADPAGE_DESCRIPTION_INFO = 'SECTION_ADPAGE_DESCRIPTION_INFO',
  SECTION_ADPAGE_DESCRIPTION_WHATSAPP = 'SECTION_ADPAGE_DESCRIPTION_WHATSAPP',
  SECTION_ADPAGE_DESCRIPTION_VIBER = 'SECTION_ADPAGE_DESCRIPTION_VIBER',
  SECTION_ADPAGE_DESCRIPTION_LINE = 'SECTION_ADPAGE_DESCRIPTION_LINE',
  SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE = 'SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE',
  SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP = 'SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP',
  SECTION_ADPAGE_IMAGE_CAROUSEL_LINE = 'SECTION_ADPAGE_IMAGE_CAROUSEL_LINE',
  SECTION_ADPAGE_IMAGE_CAROUSEL_FACEBOOK = 'SECTION_ADPAGE_IMAGE_CAROUSEL_FACEBOOK',
  SECTION_SEARCH_AD_VIEW_WHATSAPP = 'SECTION_SEARCH_AD_VIEW_WHATSAPP',
  SECTION_SERP_AD_WHATSAPP_MULTIENQUIRY = 'SECTION_SERP_AD_WHATSAPP_MULTIENQUIRY',
  SECTION_ADPAGE_RIGHT_WHATSAPP_MULTIENQUIRY = 'SECTION_ADPAGE_RIGHT_WHATSAPP_MULTIENQUIRY',
  SECTION_ADPAGE_DESCRIPTION_WHATSAPP_MULTIENQUIRY = 'SECTION_ADPAGE_DESCRIPTION_WHATSAPP_MULTIENQUIRY',
  SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP_MULTIENQUIRY = 'SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP_MULTIENQUIRY',
  SECTION_ADPAGE_BOTTOM_WHATSAPP_MULTIENQUIRY = 'SECTION_ADPAGE_BOTTOM_WHATSAPP_MULTIENQUIRY',
  SECTION_ADPAGE_TOP_CALLME = 'SECTION_ADPAGE_TOP_CALLME',
  SECTION_ADPAGE_MORTGAGE_CALCULATOR = 'SECTION_ADPAGE_MORTGAGE_CALCULATOR',
  SECTION_ADPAGE_FORM_RELATED = 'SECTION_ADPAGE_FORM_RELATED',
  SECTION_ADPAGE_FORM_RELATED_MODAL = 'SECTION_ADPAGE_FORM_RELATED_MODAL',
  SECTION_ADPAGE_MAP = 'SECTION_ADPAGE_MAP',
  SECTION_ADPAGE_RIGHT_VIEW_PHONE_MULTIENQUIRY = 'SECTION_ADPAGE_RIGHT_VIEW_PHONE_MULTIENQUIRY',
  SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE_MULTIENQUIRY = 'SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE_MULTIENQUIRY',
  SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE_MULTIENQUIRY = 'SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE_MULTIENQUIRY',
  SECTION_SHOWCASE_ADS_SALE = 'SECTION_SHOWCASE_ADS_SALE',
  SECTION_SHOWCASE_ADS_RENT = 'SECTION_SHOWCASE_ADS_RENT',
  SECTION_SEARCH_AD_CONTACT_FORM = 'SECTION_SEARCH_AD_CONTACT_FORM',
  SECTION_ADPAGE_STICKY_BOTTOM_VISIT = 'SECTION_ADPAGE_STICKY_BOTTOM_VISIT',
  SECTION_ADPAGE_STICKY_BOTTOM_CONTACT_FORM = 'SECTION_ADPAGE_STICKY_BOTTOM_CONTACT_FORM',
  SECTION_ADPAGE_TAB_VISIT = 'SECTION_ADPAGE_TAB_VISIT',
  SECTION_ADPAGE_IMAGE_CAROUSEL_VISIT = 'SECTION_ADPAGE_IMAGE_CAROUSEL_VISIT',
  SECTION_SERP_IMAGE_THUMBNAIL = 'SECTION_SERP_IMAGE_THUMBNAIL',
  SECTION_SNIPPET_VIEW_PHOTO = 'SECTION_SNIPPET_VIEW_PHOTO',
  SECTION_SNIPPET_VIEW_DETAIL  = 'SECTION_SNIPPET_VIEW_DETAIL',
  SECTION_SNIPPET_CONTACT_IN_WEB  = 'SECTION_SNIPPET_CONTACT_IN_WEB',
  SECTION_SNIPPET_HIGH_DEMAND  = 'SECTION_SNIPPET_HIGH_DEMAND',
  SECTION_DETAIL_PAGE_SHARE = 'SECTION_DETAIL_PAGE_SHARE'
}

export enum SectionId {
  SECTION_SEARCH_AD_CONTACT_FORM = 4102,
  SECTION_ADPAGE_FORM_RELATED = 51,
  SECTION_ADPAGE_FORM_RELATED_MODAL = 52,
  SECTION_ADPAGE_RIGHT_VIEW_PHONE_MULTIENQUIRY = 4407,
  SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE_MULTIENQUIRY = 4408,
  SECTION_SERP_AD_WHATSAPP_MULTIENQUIRY = 4410,
  SECTION_ADPAGE_RIGHT_WHATSAPP_MULTIENQUIRY = 4411,
  SECTION_ADPAGE_DESCRIPTION_WHATSAPP_MULTIENQUIRY = 4412,
  SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP_MULTIENQUIRY = 4413,
  SECTION_ADPAGE_BOTTOM_WHATSAPP_MULTIENQUIRY = 4414,
}
